<template>
  <div
    id="scroll-1"
    class="min-h-page-login w-full bg-color-primary p-0 relative justify-center items-center flex overflow-hidden"
  >
    <div class="decor-top"></div>
    <div class="decor-bottom"></div>
    <transition name="fade" mode="out-in">
      <div
        v-if="showTab === 'login'"
        key="login"
        class="m-auto max-w-md px-6 py-12 border-0 sm:rounded-3xl relative w-full z-11"
      >
        <img src="/assets/images/mentor/logo-primary.svg" class="m-auto" />
        <p
          class="pt-6 pb-4 text-[#212121] text-2xl text-left font-desain font-semibold"
        >
          Selamat Datang
        </p>
        <form id="login" @submit.prevent="loginSubmit">
          <div class="mb-3">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="emailFieldStatus ? 'border-error' : 'border-black-80'"
            >
              <div class="p-2 z-10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="w-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4067 4H4.59329C3.64086 4.00091 2.72786 4.37728 2.05418 5.04547C1.38042 5.71366 1.00092 6.61911 1 7.5636V16.4364C1.00092 17.381 1.38042 18.2864 2.05418 18.9545C2.72793 19.6227 3.64093 19.9991 4.59329 20H19.4067C20.3591 19.9991 21.2721 19.6227 21.9458 18.9545C22.6196 18.2863 22.9991 17.3809 23 16.4364V7.5636C22.9991 6.61904 22.6196 5.71359 21.9458 5.04547C21.2721 4.37728 20.3591 4.00091 19.4067 4ZM21.5334 16.4366C21.5334 17.6011 20.581 18.5457 19.4067 18.5457H4.59329C3.41903 18.5457 2.46661 17.6011 2.46661 16.4366V7.56378C2.46661 6.39923 3.41903 5.45467 4.59329 5.45467H19.4067C20.581 5.45467 21.5334 6.39923 21.5334 7.56378V16.4366Z"
                    fill="#212121"
                  />
                  <path
                    d="M19.8524 7.12192C19.5655 6.83827 19.1017 6.83827 18.8147 7.12192L12.0002 13.8811L5.18569 7.12192C4.89784 6.84646 4.44043 6.851 4.15718 7.13101C3.87485 7.41191 3.87025 7.86555 4.14801 8.15101L8.03013 12.0001L4.14801 15.8492C4.00685 15.9847 3.92618 16.1711 3.92435 16.3665C3.92251 16.5611 4.00043 16.7492 4.13885 16.8874C4.27818 17.0247 4.46792 17.102 4.66411 17.1002C4.86119 17.0984 5.0491 17.0183 5.1857 16.8783L9.06688 13.0283L11.4814 15.4229V15.4238C11.7683 15.7074 12.2321 15.7074 12.5191 15.4238L14.9336 13.0283L18.8147 16.8774V16.8783C19.1026 17.1538 19.56 17.1492 19.8432 16.8692C20.1256 16.5883 20.1302 16.1347 19.8524 15.8492L15.9703 12.0001L19.8515 8.15099H19.8524C20.1384 7.86643 20.1384 7.40644 19.8524 7.12188L19.8524 7.12192Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                type="text"
                name="email"
                placeholder=" "
                required
                v-focus
                v-model="loginForm.email"
                autocomplete="off"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  emailFieldStatus
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />

              <label
                for="email"
                :class="emailFieldStatus ? 'text-[#DE1306]' : 'text-[#212121]'"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
              >
                Email
              </label>
            </div>
            <p
              v-if="emailFieldStatus"
              class="text-xs text-[#DE1306] font-solusi"
            >
              Email atau Kata sandi yang kamu masukkan salah.
            </p>
          </div>
          <div class="mb-3">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="passwordFieldStatus ? 'border-error' : 'border-black-80 '"
            >
              <div class="p-2 z-10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="w-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C9.0992 2 6.74286 4.45452 6.74286 7.47619V10.0952H5.6C4.7259 10.0952 4 10.8514 4 11.7619V20.3333C4 21.2439 4.7259 22 5.6 22H18.4C19.2741 22 20 21.2439 20 20.3333V11.7619C20 10.8514 19.2741 10.0952 18.4 10.0952H17.2571V7.47619C17.2571 4.45452 14.9008 2 12 2ZM12 3.42857C14.1643 3.42857 15.8857 5.22171 15.8857 7.47619V10.0952H8.11429V7.47619C8.11429 5.22171 9.8357 3.42857 12 3.42857ZM5.6 11.5238H18.4C18.5384 11.5238 18.6286 11.6177 18.6286 11.7619V20.3333C18.6286 20.4775 18.5384 20.5714 18.4 20.5714H5.6C5.46161 20.5714 5.37143 20.4775 5.37143 20.3333V11.7619C5.37143 11.6177 5.46161 11.5238 5.6 11.5238ZM12 12.9524C10.8723 12.9524 9.94286 13.9206 9.94286 15.0952C9.94286 15.5826 10.1339 16.0058 10.4 16.3676V17.4762C10.4 18.3867 11.1259 19.1429 12 19.1429C12.8741 19.1429 13.6 18.3867 13.6 17.4762V16.3676C13.8661 16.0058 14.0571 15.5826 14.0571 15.0952C14.0571 13.9206 13.1277 12.9524 12 12.9524ZM12 14.381C12.3866 14.381 12.6857 14.6925 12.6857 15.0952C12.6857 15.311 12.5991 15.4915 12.4571 15.6235C12.3107 15.7593 12.2277 15.9546 12.2286 16.1592V17.4762C12.2286 17.6203 12.1384 17.7143 12 17.7143C11.8616 17.7143 11.7714 17.6203 11.7714 17.4762V16.1592C11.7723 15.9546 11.6893 15.7593 11.5429 15.6235C11.4009 15.4915 11.3143 15.311 11.3143 15.0952C11.3143 14.6925 11.6134 14.381 12 14.381Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                :type="[passwordLoginStatus ? 'text' : 'password']"
                name="password"
                placeholder=" "
                required
                autocomplete="on"
                v-model="loginForm.password"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  passwordFieldStatus
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />
              <div class="p-2 z-10 cursor-pointer" @click="showPasswordLogin">
                <svg
                  v-if="passwordLoginStatus"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-5 h-5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                    fill="#212121"
                  />
                </svg>
                <svg
                  v-else
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-5 h-5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <label
                for="password"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                :class="
                  passwordFieldStatus ? 'text-[#DE1306]' : 'text-[#212121]'
                "
              >
                Kata Sandi
              </label>
            </div>
            <p
              v-if="passwordFieldStatus"
              class="text-xs text-[#DE1306] font-solusi"
            >
              {{ emailFieldStatus ? 'Email atau ' : '' }} Kata sandi yang kamu
              masukkan salah.
            </p>
          </div>
          <div class="flex justify-between mb-4">
            <div class="flex items-center">
              <input
                type="checkbox"
                name="remember"
                id="remember"
                class="cursor-pointer"
              />
              <label
                for="remember"
                class="font-solusi text-sm text-[#212121] m-0 ml-2 cursor-pointer"
              >
                Ingat Saya
              </label>
            </div>
            <router-link
              to="/forgot-password"
              class="cursor-pointer text-tritary font-semibold font-solusi text-sm"
            >
              Lupa kata sandi?
            </router-link>
          </div>
          <button
            v-if="!loginSubmitProcess"
            type="submit"
            class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-3"
            :class="
              checkFormLogin
                ? 'bg-[#00446F] text-white'
                : 'bg-border text-[#9E9E9E] cursor-not-allowed'
            "
            :disabled="!checkFormLogin"
          >
            Masuk
          </button>
          <button
            v-else
            class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-3 bg-border text-[#9E9E9E] cursor-wait"
            disabled
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              class="w-6 h-6 animate-spin"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#00446F"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
          </button>
          <p class="text-center font-solusi text-sm">
            Belum punya akun?
            <a
              @click="showTab = 'register'"
              class="font-solusi font-semibold text-tritary text-sm cursor-pointer"
              >Daftar</a
            >
          </p>
        </form>
        <div class="my-4 flex justify-center">
          <a
            href="https://wa.me/6285155421080"
            target="_blank"
            class="text-[#00446F] font-solusi text-sm font-semibold px-10 py-2 rounded-full border border-gray-200 flex items-center bg-white"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="telegram"
              class="mr-2 w-4 h-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 496 512"
            >
              <path
                fill="currentColor"
                d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
              ></path>
            </svg>
            Hubungi Admin
          </a>
        </div>
      </div>
      <div
        v-else
        key="register"
        class="m-auto max-w-md px-6 pt-6 border-0 sm:rounded-3xl w-full z-11"
      >
        <img src="/assets/images/mentor/logo-primary.svg" class="m-auto" />
        <p
          class="pt-6 pb-4 text-[#212121] text-2xl text-left font-desain font-semibold"
        >
          Daftar
        </p>
        <form id="register" @submit.prevent="registerSubmit">
          <div class="mb-3">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="errors.name ? 'border-error' : 'border-black-80'"
            >
              <div class="p-2 z-10">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-6 h-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2.5C8.02887 2.5 6.41982 4.07292 6.41982 6C6.41982 7.92708 8.02879 9.5 10 9.5C11.9713 9.5 13.5803 7.92708 13.5803 6C13.5803 4.07292 11.9713 2.5 10 2.5ZM10 3.5C11.4185 3.5 12.5573 4.61328 12.5573 6C12.5573 7.38672 11.4185 8.5 10 8.5C8.58154 8.5 7.44274 7.38672 7.44274 6C7.44274 4.61328 8.58154 3.5 10 3.5ZM10 10.5C6.1467 10.5 2.95876 13.308 2.50391 16.9375C2.48592 17.0794 2.53054 17.2227 2.62711 17.3301C2.72434 17.4375 2.86352 17.4994 3.01004 17.5H16.99C17.1365 17.4993 17.2757 17.4375 17.3729 17.3301C17.4695 17.2227 17.5141 17.0794 17.4961 16.9375C17.0412 13.308 13.8533 10.5 9.99996 10.5H10ZM10 11.5C13.1401 11.5 15.7226 13.6478 16.356 16.5H3.64412C4.27745 13.6478 6.86001 11.5 10 11.5Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                type="text"
                name="name"
                placeholder=" "
                v-focus
                required
                v-model="registerForm.name"
                @change="checkName()"
                autocomplete="off"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  errors.name
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />

              <label
                for="name"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                :class="errors.name ? 'text-[#DE1306]' : 'text-[#212121]'"
              >
                Nama Lengkap
              </label>
            </div>
            <p v-if="errors.name" class="text-xs text-[#DE1306] font-solusi">
              {{ message.name }}
            </p>
          </div>

          <div class="mb-3">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="errors.email ? 'border-error' : 'border-black-80'"
            >
              <div class="p-2 z-10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="w-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4067 4H4.59329C3.64086 4.00091 2.72786 4.37728 2.05418 5.04547C1.38042 5.71366 1.00092 6.61911 1 7.5636V16.4364C1.00092 17.381 1.38042 18.2864 2.05418 18.9545C2.72793 19.6227 3.64093 19.9991 4.59329 20H19.4067C20.3591 19.9991 21.2721 19.6227 21.9458 18.9545C22.6196 18.2863 22.9991 17.3809 23 16.4364V7.5636C22.9991 6.61904 22.6196 5.71359 21.9458 5.04547C21.2721 4.37728 20.3591 4.00091 19.4067 4ZM21.5334 16.4366C21.5334 17.6011 20.581 18.5457 19.4067 18.5457H4.59329C3.41903 18.5457 2.46661 17.6011 2.46661 16.4366V7.56378C2.46661 6.39923 3.41903 5.45467 4.59329 5.45467H19.4067C20.581 5.45467 21.5334 6.39923 21.5334 7.56378V16.4366Z"
                    fill="#212121"
                  />
                  <path
                    d="M19.8524 7.12192C19.5655 6.83827 19.1017 6.83827 18.8147 7.12192L12.0002 13.8811L5.18569 7.12192C4.89784 6.84646 4.44043 6.851 4.15718 7.13101C3.87485 7.41191 3.87025 7.86555 4.14801 8.15101L8.03013 12.0001L4.14801 15.8492C4.00685 15.9847 3.92618 16.1711 3.92435 16.3665C3.92251 16.5611 4.00043 16.7492 4.13885 16.8874C4.27818 17.0247 4.46792 17.102 4.66411 17.1002C4.86119 17.0984 5.0491 17.0183 5.1857 16.8783L9.06688 13.0283L11.4814 15.4229V15.4238C11.7683 15.7074 12.2321 15.7074 12.5191 15.4238L14.9336 13.0283L18.8147 16.8774V16.8783C19.1026 17.1538 19.56 17.1492 19.8432 16.8692C20.1256 16.5883 20.1302 16.1347 19.8524 15.8492L15.9703 12.0001L19.8515 8.15099H19.8524C20.1384 7.86643 20.1384 7.40644 19.8524 7.12188L19.8524 7.12192Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                type="email"
                name="email"
                placeholder=" "
                required
                v-model="registerForm.email"
                @change="checkEmail()"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  errors.email
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />

              <label
                for="email"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                :class="errors.email ? 'text-[#DE1306]' : 'text-[#212121]'"
              >
                Email
              </label>
            </div>
            <p v-if="errors.email" class="text-xs text-[#DE1306] font-solusi">
              {{ message.email }}
            </p>
          </div>

          <div class="mb-3">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="errors.whatsapp ? 'border-error' : 'border-black-80'"
            >
              <div class="px-1.5   py-2 z-10">
                <span class="font-solusi text-sm">+62</span>
              </div>
              <input
                type="text"
                name="whatsapp"
                placeholder=" "
                required
                v-model="registerForm.whatsapp"
                @change="checkWA()"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  errors.whatsapp
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />

              <label
                for="whatsapp"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                :class="errors.whatsapp ? 'text-[#DE1306]' : 'text-[#212121]'"
              >
                Nomor Whatsapp
              </label>
            </div>
            <p
              v-if="errors.whatsapp"
              class="text-xs text-[#DE1306] font-solusi"
            >
              {{ message.whatsapp }}
            </p>
          </div>

          <div class="mb-3">
            <Dropdown
              v-model="registerForm.domisili"
              class="w-full relative bg-transparent"
              :class="errors.domisili ? 'border-[#DE1306]' : 'border-[#212121]'"
              :options="cityData"
              optionLabel="name"
              placeholder="Domisili"
              :filter="true"
              :showClear="true"
              dataKey="id"
              @change="checkDomisili"
            >
              <template #value="slotProps">
                <div
                  class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center h-full"
                >
                  <div v-if="slotProps.value" class="flex items-center">
                    <div class="p-2 z-10">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 18C8.44772 18 8 18.4477 8 19V42H6V19C6 17.3431 7.34315 16 9 16H21C22.6569 16 24 17.3431 24 19V42H22V19C22 18.4477 21.5523 18 21 18H9Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M39 20H32V18H39C40.6569 18 42 19.3431 42 21V42H40V21C40 20.4477 39.5523 20 39 20Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18 6C17.4477 6 17 6.44772 17 7V17H15V7C15 5.34315 16.3431 4 18 4H30C31.6569 4 33 5.34315 33 7V42H31V7C31 6.44772 30.5523 6 30 6H18Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M44 44H4V42H44V44Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20 8C19.4477 8 19 8.44772 19 9V11C19 11.5523 19.4477 12 20 12H22C22.5523 12 23 11.5523 23 11V9C23 8.44772 22.5523 8 22 8H20Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 20C10.4477 20 10 20.4477 10 21V23C10 23.5523 10.4477 24 11 24H13C13.5523 24 14 23.5523 14 23V21C14 20.4477 13.5523 20 13 20H11Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17 20C16.4477 20 16 20.4477 16 21V23C16 23.5523 16.4477 24 17 24H19C19.5523 24 20 23.5523 20 23V21C20 20.4477 19.5523 20 19 20H17Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20 14C19.4477 14 19 14.4477 19 15V17C19 17.5523 19.4477 18 20 18H22C22.5523 18 23 17.5523 23 17V15C23 14.4477 22.5523 14 22 14H20Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 26C10.4477 26 10 26.4477 10 27V29C10 29.5523 10.4477 30 11 30H13C13.5523 30 14 29.5523 14 29V27C14 26.4477 13.5523 26 13 26H11Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 32C10.4477 32 10 32.4477 10 33V35C10 35.5523 10.4477 36 11 36H13C13.5523 36 14 35.5523 14 35V33C14 32.4477 13.5523 32 13 32H11Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17 26C16.4477 26 16 26.4477 16 27V29C16 29.5523 16.4477 30 17 30H19C19.5523 30 20 29.5523 20 29V27C20 26.4477 19.5523 26 19 26H17Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17 32C16.4477 32 16 32.4477 16 33V35C16 35.5523 16.4477 36 17 36H19C19.5523 36 20 35.5523 20 35V33C20 32.4477 19.5523 32 19 32H17Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 8C25.4477 8 25 8.44772 25 9V11C25 11.5523 25.4477 12 26 12H28C28.5523 12 29 11.5523 29 11V9C29 8.44772 28.5523 8 28 8H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 14C25.4477 14 25 14.4477 25 15V17C25 17.5523 25.4477 18 26 18H28C28.5523 18 29 17.5523 29 17V15C29 14.4477 28.5523 14 28 14H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 20C25.4477 20 25 20.4477 25 21V23C25 23.5523 25.4477 24 26 24H28C28.5523 24 29 23.5523 29 23V21C29 20.4477 28.5523 20 28 20H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 26C25.4477 26 25 26.4477 25 27V29C25 29.5523 25.4477 30 26 30H28C28.5523 30 29 29.5523 29 29V27C29 26.4477 28.5523 26 28 26H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35 22C34.4477 22 34 22.4477 34 23V25C34 25.5523 34.4477 26 35 26H37C37.5523 26 38 25.5523 38 25V23C38 22.4477 37.5523 22 37 22H35Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35 28C34.4477 28 34 28.4477 34 29V31C34 31.5523 34.4477 32 35 32H37C37.5523 32 38 31.5523 38 31V29C38 28.4477 37.5523 28 37 28H35Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35 34C34.4477 34 34 34.4477 34 35V37C34 37.5523 34.4477 38 35 38H37C37.5523 38 38 37.5523 38 37V35C38 34.4477 37.5523 34 37 34H35Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 32C25.4477 32 25 32.4477 25 33V35C25 35.5523 25.4477 36 26 36H28C28.5523 36 29 35.5523 29 35V33C29 32.4477 28.5523 32 28 32H26Z"
                          fill="#212121"
                        />
                      </svg>
                    </div>
                    <div class="text-[#212121]">
                      {{ slotProps.value.name }}
                    </div>
                  </div>
                  <span v-else class="flex items-center">
                    <div class="p-2 z-10">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 18C8.44772 18 8 18.4477 8 19V42H6V19C6 17.3431 7.34315 16 9 16H21C22.6569 16 24 17.3431 24 19V42H22V19C22 18.4477 21.5523 18 21 18H9Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M39 20H32V18H39C40.6569 18 42 19.3431 42 21V42H40V21C40 20.4477 39.5523 20 39 20Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18 6C17.4477 6 17 6.44772 17 7V17H15V7C15 5.34315 16.3431 4 18 4H30C31.6569 4 33 5.34315 33 7V42H31V7C31 6.44772 30.5523 6 30 6H18Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M44 44H4V42H44V44Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20 8C19.4477 8 19 8.44772 19 9V11C19 11.5523 19.4477 12 20 12H22C22.5523 12 23 11.5523 23 11V9C23 8.44772 22.5523 8 22 8H20Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 20C10.4477 20 10 20.4477 10 21V23C10 23.5523 10.4477 24 11 24H13C13.5523 24 14 23.5523 14 23V21C14 20.4477 13.5523 20 13 20H11Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17 20C16.4477 20 16 20.4477 16 21V23C16 23.5523 16.4477 24 17 24H19C19.5523 24 20 23.5523 20 23V21C20 20.4477 19.5523 20 19 20H17Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20 14C19.4477 14 19 14.4477 19 15V17C19 17.5523 19.4477 18 20 18H22C22.5523 18 23 17.5523 23 17V15C23 14.4477 22.5523 14 22 14H20Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 26C10.4477 26 10 26.4477 10 27V29C10 29.5523 10.4477 30 11 30H13C13.5523 30 14 29.5523 14 29V27C14 26.4477 13.5523 26 13 26H11Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 32C10.4477 32 10 32.4477 10 33V35C10 35.5523 10.4477 36 11 36H13C13.5523 36 14 35.5523 14 35V33C14 32.4477 13.5523 32 13 32H11Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17 26C16.4477 26 16 26.4477 16 27V29C16 29.5523 16.4477 30 17 30H19C19.5523 30 20 29.5523 20 29V27C20 26.4477 19.5523 26 19 26H17Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17 32C16.4477 32 16 32.4477 16 33V35C16 35.5523 16.4477 36 17 36H19C19.5523 36 20 35.5523 20 35V33C20 32.4477 19.5523 32 19 32H17Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 8C25.4477 8 25 8.44772 25 9V11C25 11.5523 25.4477 12 26 12H28C28.5523 12 29 11.5523 29 11V9C29 8.44772 28.5523 8 28 8H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 14C25.4477 14 25 14.4477 25 15V17C25 17.5523 25.4477 18 26 18H28C28.5523 18 29 17.5523 29 17V15C29 14.4477 28.5523 14 28 14H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 20C25.4477 20 25 20.4477 25 21V23C25 23.5523 25.4477 24 26 24H28C28.5523 24 29 23.5523 29 23V21C29 20.4477 28.5523 20 28 20H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 26C25.4477 26 25 26.4477 25 27V29C25 29.5523 25.4477 30 26 30H28C28.5523 30 29 29.5523 29 29V27C29 26.4477 28.5523 26 28 26H26Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35 22C34.4477 22 34 22.4477 34 23V25C34 25.5523 34.4477 26 35 26H37C37.5523 26 38 25.5523 38 25V23C38 22.4477 37.5523 22 37 22H35Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35 28C34.4477 28 34 28.4477 34 29V31C34 31.5523 34.4477 32 35 32H37C37.5523 32 38 31.5523 38 31V29C38 28.4477 37.5523 28 37 28H35Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35 34C34.4477 34 34 34.4477 34 35V37C34 37.5523 34.4477 38 35 38H37C37.5523 38 38 37.5523 38 37V35C38 34.4477 37.5523 34 37 34H35Z"
                          fill="#212121"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 32C25.4477 32 25 32.4477 25 33V35C25 35.5523 25.4477 36 26 36H28C28.5523 36 29 35.5523 29 35V33C29 32.4477 28.5523 32 28 32H26Z"
                          fill="#212121"
                        />
                      </svg>
                    </div>
                    <div
                      class="pl-1.5"
                      :class="
                        errors.domisili ? 'text-[#DE1306]' : 'text-[#212121]'
                      "
                    >
                      {{ slotProps.placeholder }}
                    </div>
                  </span>
                </div>
                <label
                  v-if="slotProps.value"
                  class="absolute duration-300 transform -top-2.5 left-2 z-1 origin-0 bg-[#f9fcff] m-0 px-1.5 py-0 font-solusi rounded-md text-xs"
                  :class="errors.domisili ? 'text-[#DE1306]' : 'text-[#212121]'"
                >
                  Domisili
                </label>
              </template>
              <template #option="slotProps">
                <div class="font-solusi">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
            <p
              v-if="errors.domisili"
              class="text-xs text-[#DE1306] font-solusi"
            >
              Domisili harus diisi
            </p>
          </div>

          <div class="mb-3">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="errors.password ? 'border-error' : 'border-black-80 '"
            >
              <div class="p-2 z-10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="w-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C9.0992 2 6.74286 4.45452 6.74286 7.47619V10.0952H5.6C4.7259 10.0952 4 10.8514 4 11.7619V20.3333C4 21.2439 4.7259 22 5.6 22H18.4C19.2741 22 20 21.2439 20 20.3333V11.7619C20 10.8514 19.2741 10.0952 18.4 10.0952H17.2571V7.47619C17.2571 4.45452 14.9008 2 12 2ZM12 3.42857C14.1643 3.42857 15.8857 5.22171 15.8857 7.47619V10.0952H8.11429V7.47619C8.11429 5.22171 9.8357 3.42857 12 3.42857ZM5.6 11.5238H18.4C18.5384 11.5238 18.6286 11.6177 18.6286 11.7619V20.3333C18.6286 20.4775 18.5384 20.5714 18.4 20.5714H5.6C5.46161 20.5714 5.37143 20.4775 5.37143 20.3333V11.7619C5.37143 11.6177 5.46161 11.5238 5.6 11.5238ZM12 12.9524C10.8723 12.9524 9.94286 13.9206 9.94286 15.0952C9.94286 15.5826 10.1339 16.0058 10.4 16.3676V17.4762C10.4 18.3867 11.1259 19.1429 12 19.1429C12.8741 19.1429 13.6 18.3867 13.6 17.4762V16.3676C13.8661 16.0058 14.0571 15.5826 14.0571 15.0952C14.0571 13.9206 13.1277 12.9524 12 12.9524ZM12 14.381C12.3866 14.381 12.6857 14.6925 12.6857 15.0952C12.6857 15.311 12.5991 15.4915 12.4571 15.6235C12.3107 15.7593 12.2277 15.9546 12.2286 16.1592V17.4762C12.2286 17.6203 12.1384 17.7143 12 17.7143C11.8616 17.7143 11.7714 17.6203 11.7714 17.4762V16.1592C11.7723 15.9546 11.6893 15.7593 11.5429 15.6235C11.4009 15.4915 11.3143 15.311 11.3143 15.0952C11.3143 14.6925 11.6134 14.381 12 14.381Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                :type="[passwordRegisterStatus ? 'text' : 'password']"
                name="password"
                placeholder=" "
                required
                v-model="registerForm.password"
                @input="checkPassword"
                autocomplete="on"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  errors.password
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />
              <div
                class="p-2 z-10 cursor-pointer"
                @click="showPasswordRegister"
              >
                <svg
                  v-if="passwordRegisterStatus"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-5 h-5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                    fill="#212121"
                  />
                </svg>
                <svg
                  v-else
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-5 h-5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <label
                for="password"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                :class="errors.password ? 'text-[#DE1306]' : 'text-[#212121]'"
              >
                Kata Sandi
              </label>
            </div>
            <p
              v-if="errors.password"
              class="text-xs text-[#DE1306] font-solusi"
            >
              {{ message.password }}
            </p>
          </div>

          <div class="mb-3">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="
                errors.passwordConfirmation
                  ? 'border-error'
                  : 'border-black-80 '
              "
            >
              <div class="p-2 z-10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="w-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C9.0992 2 6.74286 4.45452 6.74286 7.47619V10.0952H5.6C4.7259 10.0952 4 10.8514 4 11.7619V20.3333C4 21.2439 4.7259 22 5.6 22H18.4C19.2741 22 20 21.2439 20 20.3333V11.7619C20 10.8514 19.2741 10.0952 18.4 10.0952H17.2571V7.47619C17.2571 4.45452 14.9008 2 12 2ZM12 3.42857C14.1643 3.42857 15.8857 5.22171 15.8857 7.47619V10.0952H8.11429V7.47619C8.11429 5.22171 9.8357 3.42857 12 3.42857ZM5.6 11.5238H18.4C18.5384 11.5238 18.6286 11.6177 18.6286 11.7619V20.3333C18.6286 20.4775 18.5384 20.5714 18.4 20.5714H5.6C5.46161 20.5714 5.37143 20.4775 5.37143 20.3333V11.7619C5.37143 11.6177 5.46161 11.5238 5.6 11.5238ZM12 12.9524C10.8723 12.9524 9.94286 13.9206 9.94286 15.0952C9.94286 15.5826 10.1339 16.0058 10.4 16.3676V17.4762C10.4 18.3867 11.1259 19.1429 12 19.1429C12.8741 19.1429 13.6 18.3867 13.6 17.4762V16.3676C13.8661 16.0058 14.0571 15.5826 14.0571 15.0952C14.0571 13.9206 13.1277 12.9524 12 12.9524ZM12 14.381C12.3866 14.381 12.6857 14.6925 12.6857 15.0952C12.6857 15.311 12.5991 15.4915 12.4571 15.6235C12.3107 15.7593 12.2277 15.9546 12.2286 16.1592V17.4762C12.2286 17.6203 12.1384 17.7143 12 17.7143C11.8616 17.7143 11.7714 17.6203 11.7714 17.4762V16.1592C11.7723 15.9546 11.6893 15.7593 11.5429 15.6235C11.4009 15.4915 11.3143 15.311 11.3143 15.0952C11.3143 14.6925 11.6134 14.381 12 14.381Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                :type="[passwordConfirmStatus ? 'text' : 'password']"
                name="password-confirmation"
                placeholder=" "
                required
                autocomplete="on"
                @input="checkPassword"
                v-model="registerForm.passwordConfirmation"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  errors.passwordConfirmation
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />
              <div class="p-2 z-10 cursor-pointer" @click="showPasswordConfirm">
                <svg
                  v-if="passwordConfirmStatus"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-5 h-5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                    fill="#212121"
                  />
                </svg>
                <svg
                  v-else
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-5 h-5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <label
                for="password-confirmation"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                :class="
                  errors.passwordConfirmation
                    ? 'text-[#DE1306]'
                    : 'text-[#212121]'
                "
              >
                Konfirmasi Kata Sandi
              </label>
            </div>
            <p
              v-if="errors.passwordConfirmation"
              class="text-xs text-[#DE1306] font-solusi"
            >
              {{ message.passwordConfirmation }}
            </p>
          </div>

          <div class="flex items-center mb-2">
            <input
              type="checkbox"
              name="prakerja"
              id="prakerja"
              class="cursor-pointer"
              v-model="useVoucher"
            />
            <label
              for="prakerja"
              class="font-solusi text-sm text-[#212121] m-0 ml-2 cursor-pointer"
            >
              Kamu peserta prakerja?
            </label>
          </div>

          <div v-if="useVoucher" class="mb-3 duration-500">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="errors.voucher ? 'border-error' : 'border-black-80'"
            >
              <div class="p-2 z-10">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  class="w-6 h-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.4333 4.16663H1.63325C1.19107 4.16663 0.833252 4.52445 0.833252 4.96663V7.90423C0.833252 8.25111 1.06215 8.54485 1.38715 8.66517C1.99809 8.89017 2.43325 9.47767 2.43325 10.1667C2.43325 10.8558 1.99809 11.4433 1.38715 11.6683C1.06215 11.7886 0.833252 12.0823 0.833252 12.4292V15.3668C0.833252 15.809 1.19107 16.1668 1.63325 16.1668H18.4333C18.8754 16.1668 19.2333 15.809 19.2333 15.3668V4.96683C19.2333 4.52465 18.8754 4.16683 18.4333 4.16683V4.16663ZM18.4333 15.3666H6.83325V14.5666C6.83325 14.3455 6.65435 14.1666 6.43325 14.1666C6.21216 14.1666 6.03325 14.3455 6.03325 14.5666V15.3666H1.63325V12.4376C1.63794 12.4329 1.6481 12.4251 1.6645 12.4189C2.60278 12.072 3.23326 11.1673 3.23326 10.1665C3.23326 9.16564 2.6028 8.26102 1.6645 7.91408C1.6481 7.90861 1.63794 7.90079 1.63325 7.89533V4.96633H6.03325V5.76633C6.03325 5.98743 6.21216 6.16633 6.43325 6.16633C6.65435 6.16633 6.83325 5.98742 6.83325 5.76633V4.96633H18.4333V15.3666Z"
                    fill="#212121"
                  />
                  <path
                    d="M6.4332 11.7666C6.2121 11.7666 6.0332 11.9455 6.0332 12.1666V12.9666C6.0332 13.1877 6.21211 13.3666 6.4332 13.3666C6.6543 13.3666 6.8332 13.1877 6.8332 12.9666V12.1666C6.8332 11.9455 6.6543 11.7666 6.4332 11.7666Z"
                    fill="#212121"
                  />
                  <path
                    d="M6.4332 9.36658C6.2121 9.36658 6.0332 9.54548 6.0332 9.76658V10.5666C6.0332 10.7877 6.21211 10.9666 6.4332 10.9666C6.6543 10.9666 6.8332 10.7877 6.8332 10.5666V9.76658C6.8332 9.54548 6.6543 9.36658 6.4332 9.36658Z"
                    fill="#212121"
                  />
                  <path
                    d="M6.4332 6.96655C6.2121 6.96655 6.0332 7.14546 6.0332 7.36655V8.16655C6.0332 8.38765 6.21211 8.56655 6.4332 8.56655C6.6543 8.56655 6.8332 8.38765 6.8332 8.16655V7.36655C6.8332 7.14545 6.6543 6.96655 6.4332 6.96655Z"
                    fill="#212121"
                  />
                  <path
                    d="M11.4332 9.5666C12.2051 9.5666 12.8332 8.93848 12.8332 8.1666C12.8332 7.39472 12.2051 6.7666 11.4332 6.7666C10.6613 6.7666 10.0332 7.39472 10.0332 8.1666C10.0332 8.93848 10.6613 9.5666 11.4332 9.5666ZM11.4332 7.5666C11.7637 7.5666 12.0332 7.83614 12.0332 8.1666C12.0332 8.49706 11.7637 8.7666 11.4332 8.7666C11.1027 8.7666 10.8332 8.49706 10.8332 8.1666C10.8332 7.83614 11.1027 7.5666 11.4332 7.5666Z"
                    fill="#212121"
                  />
                  <path
                    d="M14.4332 10.7666C13.6613 10.7666 13.0332 11.3947 13.0332 12.1666C13.0332 12.9385 13.6613 13.5666 14.4332 13.5666C15.2051 13.5666 15.8332 12.9385 15.8332 12.1666C15.8332 11.3947 15.2051 10.7666 14.4332 10.7666ZM14.4332 12.7666C14.1027 12.7666 13.8332 12.4971 13.8332 12.1666C13.8332 11.8361 14.1027 11.5666 14.4332 11.5666C14.7637 11.5666 15.0332 11.8361 15.0332 12.1666C15.0332 12.4971 14.7637 12.7666 14.4332 12.7666Z"
                    fill="#212121"
                  />
                  <path
                    d="M10.177 13.4735C10.252 13.536 10.3426 13.5665 10.4332 13.5665C10.548 13.5665 10.6613 13.5173 10.7402 13.4228L15.7402 7.42277C15.8816 7.25324 15.859 7.00089 15.6887 6.85949C15.5184 6.71808 15.2668 6.74074 15.1254 6.91105L10.1254 12.911C9.98477 13.0798 10.0074 13.3321 10.177 13.4735Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                type="text"
                name="voucher"
                placeholder=" "
                v-model="registerForm.voucher"
                autocomplete="off"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0 font-solusi"
                :class="
                  errors.voucher
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />

              <label
                for="voucher"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                :class="errors.voucher ? 'text-[#DE1306]' : 'text-[#212121]'"
              >
                Kode Voucher
              </label>
            </div>
            <p v-if="errors.voucher" class="text-xs text-[#DE1306] font-solusi">
              {{ message.voucher }}
            </p>
          </div>

          <div class="flex mb-3">
            <input
              type="checkbox"
              name="sk"
              id="sk"
              class="cursor-pointer mt-1"
              v-model="registerForm.agreement"
            />
            <label for="sk" class="font-solusi text-sm text-[#212121] m-0 ml-2">
              Dengan ini saya menyatakan telah membaca dan setuju kepada
              <router-link to="/sk" class="font-solusi text-sm text-tritary">
                Syarat dan Ketentuan
              </router-link>
              dan
              <router-link
                to="/kebijakan"
                class="font-solusi text-sm text-tritary"
              >
                Kebijakan Privasi.
              </router-link>
            </label>
          </div>
          <span class="font-solusi text-xs text-[#757575]">Note:</span>
          <p class="font-solusi text-xs text-[#757575] mb-4">
            Demi kelancaran pelaporan Penyelesaian Kelas, Anda wajib untuk
            memasukkan informasi nama yang identik (sama persis) dengan Kartu
            Tanda Penduduk (KTP) Anda & nama yang tertera di akun Prakerja Anda.
            Email dan Nomor HP Whatsapp harus diisi dengan benar.
          </p>
          <p
            v-if="errors.general"
            class="font-solusi text-sm text-[#DE1306] text-center"
          >
            {{ message.general }}
          </p>
          <button
            v-if="!registerSubmitProcess"
            type="submit"
            class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-3"
            :class="
              checkFormRegister
                ? 'bg-[#00446F] text-white'
                : 'bg-border text-[#9E9E9E] cursor-not-allowed'
            "
            :disabled="!checkFormRegister"
          >
            Daftar
          </button>
          <button
            v-else
            class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-3 bg-border text-[#9E9E9E] cursor-wait"
            disabled
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              class="w-6 h-6 animate-spin"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#00446F"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
          </button>
          <p class="text-center font-solusi text-sm">
            Sudah punya akun?
            <a
              @click="showTab = 'login'"
              class="font-solusi font-semibold text-tritary text-sm cursor-pointer"
              >Masuk</a
            >
          </p>
        </form>
        <div class="mt-4 mb-8 flex justify-center">
          <a
            href="https://wa.me/6285155421080"
            target="_blank"
            class="text-[#00446F] font-solusi text-sm font-semibold px-10 py-2 rounded-full border border-gray-200 flex items-center bg-white"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="telegram"
              class="mr-2 w-4 h-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 496 512"
            >
              <path
                fill="currentColor"
                d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
              ></path>
            </svg>
            Hubungi Admin
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import Cookies from 'js-cookie'
import { City } from '@/dto'
import CityModel from '@/models/CityModel'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import { useToast } from 'vue-toastification'
import ResetPassword from '@/main/components/ResetPassword.vue'

export default defineComponent({
  name: 'Login',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      showTab: 'login',
      loginForm: {
        email: '',
        password: '',
        remember: false,
      },
      registerForm: {
        name: '',
        email: '',
        whatsapp: '',
        password: '',
        passwordConfirmation: '',
        voucher: '',
        agreement: false,
        domisili: '' as any,
      },
      errors: {
        name: false,
        email: false,
        whatsapp: false,
        password: false,
        passwordConfirmation: false,
        voucher: false,
        agreement: false,
        general: false,
        domisili: false,
      },
      message: {
        name: '',
        email: '',
        whatsapp: '',
        password: '',
        passwordConfirmation: '',
        voucher: '',
        general: '',
        domisili: '',
      },
      passwordLoginStatus: false,
      passwordRegisterStatus: false,
      passwordConfirmStatus: false,
      emailFieldStatus: false,
      passwordFieldStatus: false,
      registerSubmitProcess: false,
      loginSubmitProcess: false,
      modalPackStatus: false,
      imageUrlModal: '',
      firstTextModal: '',
      secondTextModal: '',
      firstButtonModal: '',
      secondButtonModal: '',
      secondButtonModalAction: Function as any,
      useVoucher: false,
      chanceVoucher: 1,
      redirect: this.$route.query.redirect as string,
      cityData: [] as City[],
    }
  },
  created() {
    if (this.$route.query.q == 'daftar') {
      this.showTab = 'register'
    }
    this.getCity()
  },
  computed: {
    // console.log(this.redirect)
    checkFormLogin(): boolean {
      if (this.loginForm.email != '' && this.loginForm.password != '') {
        return true
      } else {
        return false
      }
    },
    checkFormRegister(): boolean {
      if (
        this.registerForm.name != '' &&
        this.registerForm.email != '' &&
        this.registerForm.whatsapp != '' &&
        this.registerForm.password != '' &&
        this.registerForm.passwordConfirmation != '' &&
        this.registerForm.domisili?.id > 0 &&
        this.registerForm.agreement == true &&
        this.errors.name == false &&
        this.errors.email == false &&
        this.errors.password == false &&
        this.errors.passwordConfirmation == false &&
        this.errors.domisili == false
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getCity() {
      const { data } = await CityModel.list()
      const sortedCity = data.sort(function(a, b) {
        if (a.name < b.name) return -1
        else if (a.name > b.name) return 1
        return 0
      })
      this.cityData = sortedCity
    },
    showPasswordLogin() {
      this.passwordLoginStatus = !this.passwordLoginStatus
    },
    showPasswordRegister() {
      this.passwordRegisterStatus = !this.passwordRegisterStatus
    },
    showPasswordConfirm() {
      this.passwordConfirmStatus = !this.passwordConfirmStatus
    },
    resetErrors() {
      this.errors.name = false
      this.errors.email = false
      this.errors.whatsapp = false
      this.errors.password = false
      this.errors.passwordConfirmation = false
      this.errors.voucher = false
      this.errors.general = false
    },
    checkName() {
      this.errors.name = false
      if (this.registerForm.name.indexOf('@') !== -1) {
        this.errors.name = true
        this.message.name = 'Isi Nama Lengkap dengan benar.'
        return
      }
    },

    checkEmail() {
      this.errors.email = false
      if (this.registerForm.email.indexOf('@') === -1) {
        this.errors.email = true
        this.message.email = 'Isi Email dengan benar.'
        return
      }
    },

    checkWA() {
      this.errors.whatsapp = false
      this.message.whatsapp = ''

      const numbers = /^[+]?[0-9 ]+$/
      if (!this.registerForm.whatsapp.match(numbers)) {
        this.errors.whatsapp = true
        this.message.whatsapp = 'Nomor yang Anda masukkan harus angka.'
        return
      }

      if (this.registerForm.whatsapp.length < 9) {
        this.errors.whatsapp = true
        this.message.whatsapp =
          'Nomor yang Anda masukkan harus lebih dari 9 digit.'
        return
      }

      const no = this.registerForm.whatsapp.substring(0, 1)
      if (no !== '8') {
        this.errors.whatsapp = true
        this.message.whatsapp =
          'Nomor yang Anda masukkan harus diawali 8 (ex: 856 XXXX XXXX).'
        return
      }
    },
    checkPassword() {
      this.errors.password = false
      this.errors.passwordConfirmation = false
      if (this.registerForm.password.length < 8) {
        this.errors.password = true
        this.message.password =
          'Min. 8 karakter terdiri dari angka, huruf, simbol.'
      }
      if (
        this.registerForm.password !== this.registerForm.passwordConfirmation
      ) {
        this.errors.passwordConfirmation = true
        this.message.passwordConfirmation =
          'Konfirmasi kata sandi tidak sama dengan kata sandi.'
      }
      return
    },
    checkPasswordConfirmation() {
      this.errors.passwordConfirmation = false
      if (
        this.registerForm.password !== this.registerForm.passwordConfirmation
      ) {
        this.errors.passwordConfirmation = true
        this.message.passwordConfirmation =
          'Konfirmasi kata sandi tidak sama dengan kata sandi.'
        return
      }
    },
    async checkVoucher() {
      try {
        const { success } = await VoucherClassroomModel.checkVoucher(
          this.registerForm.voucher
        )
        if (success) {
          return true
        } else {
          this.errors.voucher = true
          this.message.voucher = 'Kode Tidak Valid'
          this.chanceVoucher = this.chanceVoucher + 1
          this.registerSubmitProcess = false
          return false
        }
      } catch (err) {
        this.errors.voucher = true
        this.message.voucher = 'Kode Tidak Valid'
        this.chanceVoucher = this.chanceVoucher + 1
        this.registerSubmitProcess = false
        return false
      }
    },
    checkDomisili() {
      if (this.registerForm.domisili?.id > 0) {
        this.errors.domisili = false
      } else {
        this.errors.domisili = true
      }
    },
    async registerSubmit() {
      this.resetErrors()
      this.checkName()
      this.checkEmail()
      this.checkWA()
      this.checkPassword()
      this.checkDomisili()
      if (
        !this.errors.name &&
        !this.errors.email &&
        !this.errors.whatsapp &&
        !this.errors.password &&
        !this.errors.passwordConfirmation &&
        !this.errors.domisili &&
        this.registerForm.agreement
      ) {
        this.registerSubmitProcess = true
        const whatsapp = '62' + this.registerForm.whatsapp
        if (this.useVoucher) {
          const check = await this.checkVoucher()
          if (check) {
            const { data }: any = await Auth.register(
              this.registerForm.name,
              this.registerForm.email,
              whatsapp,
              this.registerForm.domisili?.id,
              this.registerForm.password
            )
            console.log(data)
            if (data === 'isExistingUser') {
              this.errors.email = true
              this.message.email = 'Email sudah terdaftar.'
              this.registerSubmitProcess = false
              return
            }

            if (data?.data?.regsData == 'verify-email') {
              this.$router.push('/verify-email?from=register')
              return
            }

            if (data?.data.regsData?.success == false) {
              if (
                data.data.regsData.errors[0].message ===
                'User exists with same email'
              ) {
                this.errors.email = true
                this.message.email = 'Email sudah terdaftar.'
              } else if (
                data.data.regsData.errors[0].message === 'Email sudah digunakan'
              ) {
                this.errors.email = true
                this.message.email = 'Email sudah terdaftar.'
              } else if (
                data.data.regsData.errors[0].message ===
                'Nomor Whatsapp telah digunakan'
              ) {
                this.errors.whatsapp = true
                this.message.whatsapp = 'Nomor Whatsapp sudah digunakan.'
              } else {
                this.errors.general = true
                this.message.general =
                  'Terjadi kesalahan, silahkan coba kembali.'
              }
            } else {
              const data = await Auth.LoginRedeem(
                this.registerForm.email,
                this.registerForm.password,
                this.registerForm.voucher
              )
              // console.log(data)
              if (!data) {
                this.errors.general = true
                this.message.general =
                  'Terjadi kesalahan, silahkan coba kembali.'
              } else {
                window.location.assign('/')
              }
            }
            this.registerSubmitProcess = false
          }
        } else {
          const { data }: any = await Auth.register(
            this.registerForm.name,
            this.registerForm.email,
            whatsapp,
            this.registerForm.domisili?.id,
            this.registerForm.password
          )
          if (data === 'isExistingUser') {
            this.errors.email = true
            this.message.email = 'Email sudah terdaftar.'
          }

          if (data?.data?.regsData == 'verify-email') {
            this.$router.push('/verify-email?from=register')
            return
          }

          if (data.data?.regsData?.success == false) {
            this.modalPackStatus = true
            if (
              data.data?.regsData.errors[0].message ===
              'User exists with same email'
            ) {
              this.errors.email = true
              this.message.email = 'Email sudah terdaftar.'
            } else if (
              data.data?.regsData.errors[0].message === 'Email sudah terdaftar.'
            ) {
              this.errors.email = true
              this.message.email = 'Email sudah terdaftar.'
            } else if (
              data.data?.regsData.errors[0].message ===
              'Nomor Whatsapp telah digunakan'
            ) {
              this.errors.whatsapp = true
              this.message.whatsapp = 'Nomor Whatsapp sudah digunakan.'
            } else {
              this.errors.general = true
              this.message.general = 'Terjadi kesalahan, silahkan coba kembali.'
            }
            this.registerSubmitProcess = false
          } else {
            if (this.redirect) {
              window.location.assign(this.redirect)
            } else {
              await Auth.checkUser(Cookies.getJSON('auth'), false, false)
            }
            this.registerSubmitProcess = false
          }
        }
      } else {
        if (!this.registerForm.agreement) {
          this.errors.agreement = true
        }
        return
      }
    },

    async loginSubmit() {
      this.emailFieldStatus = false
      this.passwordFieldStatus = false

      if (!this.loginForm.email || !this.loginForm.password) {
        if (!this.loginForm.email) {
          this.emailFieldStatus = true
        }
        if (!this.loginForm.password) {
          this.passwordFieldStatus = true
        }
        return
      }

      this.loginSubmitProcess = true
      try {
        const data: any = await Auth.Login(
          this.loginForm.email,
          this.loginForm.password
        )
        if (data?.data == 'reset-password') {
          this.passwordFieldStatus = true
          this.toast.error(ResetPassword)
        } else if (data?.data == 'verify-email') {
          this.$router.push('/verify-email')
        } else {
          if (data?.data === false) {
            this.emailFieldStatus = true
            this.passwordFieldStatus = true
          } else {
            if (data?.data?.regsData?.success == false) {
              if (
                data?.data?.regsData?.errors[0]?.message ==
                'Email sudah digunakan'
              ) {
                this.passwordFieldStatus = true
              } else {
                this.emailFieldStatus = true
                this.passwordFieldStatus = true
              }
            } else {
              if (this.redirect) {
                window.location.assign(this.redirect)
              } else {
                await Auth.checkUser(Cookies.getJSON('auth'), false, false)
              }
            }
          }
        }
        this.loginSubmitProcess = false
      } catch (error) {
        this.emailFieldStatus = true
        this.passwordFieldStatus = true
        this.loginSubmitProcess = false
      }
    },
    popup() {
      const route = process.env.VUE_APP_IDP_FORGOT_PASSWORD
      window.open(route, '_blank', 'width=900, height=600')
    },
  },
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#scroll-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
#scroll-1::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
#scroll-1::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #9e9e9e;
}
::v-deep(.p-inputtext) {
  padding: 0;
}
</style>
