
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import Cookies from 'js-cookie'
import { City } from '@/dto'
import CityModel from '@/models/CityModel'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import { useToast } from 'vue-toastification'
import ResetPassword from '@/main/components/ResetPassword.vue'

export default defineComponent({
  name: 'Login',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      showTab: 'login',
      loginForm: {
        email: '',
        password: '',
        remember: false,
      },
      registerForm: {
        name: '',
        email: '',
        whatsapp: '',
        password: '',
        passwordConfirmation: '',
        voucher: '',
        agreement: false,
        domisili: '' as any,
      },
      errors: {
        name: false,
        email: false,
        whatsapp: false,
        password: false,
        passwordConfirmation: false,
        voucher: false,
        agreement: false,
        general: false,
        domisili: false,
      },
      message: {
        name: '',
        email: '',
        whatsapp: '',
        password: '',
        passwordConfirmation: '',
        voucher: '',
        general: '',
        domisili: '',
      },
      passwordLoginStatus: false,
      passwordRegisterStatus: false,
      passwordConfirmStatus: false,
      emailFieldStatus: false,
      passwordFieldStatus: false,
      registerSubmitProcess: false,
      loginSubmitProcess: false,
      modalPackStatus: false,
      imageUrlModal: '',
      firstTextModal: '',
      secondTextModal: '',
      firstButtonModal: '',
      secondButtonModal: '',
      secondButtonModalAction: Function as any,
      useVoucher: false,
      chanceVoucher: 1,
      redirect: this.$route.query.redirect as string,
      cityData: [] as City[],
    }
  },
  created() {
    if (this.$route.query.q == 'daftar') {
      this.showTab = 'register'
    }
    this.getCity()
  },
  computed: {
    // console.log(this.redirect)
    checkFormLogin(): boolean {
      if (this.loginForm.email != '' && this.loginForm.password != '') {
        return true
      } else {
        return false
      }
    },
    checkFormRegister(): boolean {
      if (
        this.registerForm.name != '' &&
        this.registerForm.email != '' &&
        this.registerForm.whatsapp != '' &&
        this.registerForm.password != '' &&
        this.registerForm.passwordConfirmation != '' &&
        this.registerForm.domisili?.id > 0 &&
        this.registerForm.agreement == true &&
        this.errors.name == false &&
        this.errors.email == false &&
        this.errors.password == false &&
        this.errors.passwordConfirmation == false &&
        this.errors.domisili == false
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getCity() {
      const { data } = await CityModel.list()
      const sortedCity = data.sort(function(a, b) {
        if (a.name < b.name) return -1
        else if (a.name > b.name) return 1
        return 0
      })
      this.cityData = sortedCity
    },
    showPasswordLogin() {
      this.passwordLoginStatus = !this.passwordLoginStatus
    },
    showPasswordRegister() {
      this.passwordRegisterStatus = !this.passwordRegisterStatus
    },
    showPasswordConfirm() {
      this.passwordConfirmStatus = !this.passwordConfirmStatus
    },
    resetErrors() {
      this.errors.name = false
      this.errors.email = false
      this.errors.whatsapp = false
      this.errors.password = false
      this.errors.passwordConfirmation = false
      this.errors.voucher = false
      this.errors.general = false
    },
    checkName() {
      this.errors.name = false
      if (this.registerForm.name.indexOf('@') !== -1) {
        this.errors.name = true
        this.message.name = 'Isi Nama Lengkap dengan benar.'
        return
      }
    },

    checkEmail() {
      this.errors.email = false
      if (this.registerForm.email.indexOf('@') === -1) {
        this.errors.email = true
        this.message.email = 'Isi Email dengan benar.'
        return
      }
    },

    checkWA() {
      this.errors.whatsapp = false
      this.message.whatsapp = ''

      const numbers = /^[+]?[0-9 ]+$/
      if (!this.registerForm.whatsapp.match(numbers)) {
        this.errors.whatsapp = true
        this.message.whatsapp = 'Nomor yang Anda masukkan harus angka.'
        return
      }

      if (this.registerForm.whatsapp.length < 9) {
        this.errors.whatsapp = true
        this.message.whatsapp =
          'Nomor yang Anda masukkan harus lebih dari 9 digit.'
        return
      }

      const no = this.registerForm.whatsapp.substring(0, 1)
      if (no !== '8') {
        this.errors.whatsapp = true
        this.message.whatsapp =
          'Nomor yang Anda masukkan harus diawali 8 (ex: 856 XXXX XXXX).'
        return
      }
    },
    checkPassword() {
      this.errors.password = false
      this.errors.passwordConfirmation = false
      if (this.registerForm.password.length < 8) {
        this.errors.password = true
        this.message.password =
          'Min. 8 karakter terdiri dari angka, huruf, simbol.'
      }
      if (
        this.registerForm.password !== this.registerForm.passwordConfirmation
      ) {
        this.errors.passwordConfirmation = true
        this.message.passwordConfirmation =
          'Konfirmasi kata sandi tidak sama dengan kata sandi.'
      }
      return
    },
    checkPasswordConfirmation() {
      this.errors.passwordConfirmation = false
      if (
        this.registerForm.password !== this.registerForm.passwordConfirmation
      ) {
        this.errors.passwordConfirmation = true
        this.message.passwordConfirmation =
          'Konfirmasi kata sandi tidak sama dengan kata sandi.'
        return
      }
    },
    async checkVoucher() {
      try {
        const { success } = await VoucherClassroomModel.checkVoucher(
          this.registerForm.voucher
        )
        if (success) {
          return true
        } else {
          this.errors.voucher = true
          this.message.voucher = 'Kode Tidak Valid'
          this.chanceVoucher = this.chanceVoucher + 1
          this.registerSubmitProcess = false
          return false
        }
      } catch (err) {
        this.errors.voucher = true
        this.message.voucher = 'Kode Tidak Valid'
        this.chanceVoucher = this.chanceVoucher + 1
        this.registerSubmitProcess = false
        return false
      }
    },
    checkDomisili() {
      if (this.registerForm.domisili?.id > 0) {
        this.errors.domisili = false
      } else {
        this.errors.domisili = true
      }
    },
    async registerSubmit() {
      this.resetErrors()
      this.checkName()
      this.checkEmail()
      this.checkWA()
      this.checkPassword()
      this.checkDomisili()
      if (
        !this.errors.name &&
        !this.errors.email &&
        !this.errors.whatsapp &&
        !this.errors.password &&
        !this.errors.passwordConfirmation &&
        !this.errors.domisili &&
        this.registerForm.agreement
      ) {
        this.registerSubmitProcess = true
        const whatsapp = '62' + this.registerForm.whatsapp
        if (this.useVoucher) {
          const check = await this.checkVoucher()
          if (check) {
            const { data }: any = await Auth.register(
              this.registerForm.name,
              this.registerForm.email,
              whatsapp,
              this.registerForm.domisili?.id,
              this.registerForm.password
            )
            console.log(data)
            if (data === 'isExistingUser') {
              this.errors.email = true
              this.message.email = 'Email sudah terdaftar.'
              this.registerSubmitProcess = false
              return
            }

            if (data?.data?.regsData == 'verify-email') {
              this.$router.push('/verify-email?from=register')
              return
            }

            if (data?.data.regsData?.success == false) {
              if (
                data.data.regsData.errors[0].message ===
                'User exists with same email'
              ) {
                this.errors.email = true
                this.message.email = 'Email sudah terdaftar.'
              } else if (
                data.data.regsData.errors[0].message === 'Email sudah digunakan'
              ) {
                this.errors.email = true
                this.message.email = 'Email sudah terdaftar.'
              } else if (
                data.data.regsData.errors[0].message ===
                'Nomor Whatsapp telah digunakan'
              ) {
                this.errors.whatsapp = true
                this.message.whatsapp = 'Nomor Whatsapp sudah digunakan.'
              } else {
                this.errors.general = true
                this.message.general =
                  'Terjadi kesalahan, silahkan coba kembali.'
              }
            } else {
              const data = await Auth.LoginRedeem(
                this.registerForm.email,
                this.registerForm.password,
                this.registerForm.voucher
              )
              // console.log(data)
              if (!data) {
                this.errors.general = true
                this.message.general =
                  'Terjadi kesalahan, silahkan coba kembali.'
              } else {
                window.location.assign('/')
              }
            }
            this.registerSubmitProcess = false
          }
        } else {
          const { data }: any = await Auth.register(
            this.registerForm.name,
            this.registerForm.email,
            whatsapp,
            this.registerForm.domisili?.id,
            this.registerForm.password
          )
          if (data === 'isExistingUser') {
            this.errors.email = true
            this.message.email = 'Email sudah terdaftar.'
          }

          if (data?.data?.regsData == 'verify-email') {
            this.$router.push('/verify-email?from=register')
            return
          }

          if (data.data?.regsData?.success == false) {
            this.modalPackStatus = true
            if (
              data.data?.regsData.errors[0].message ===
              'User exists with same email'
            ) {
              this.errors.email = true
              this.message.email = 'Email sudah terdaftar.'
            } else if (
              data.data?.regsData.errors[0].message === 'Email sudah terdaftar.'
            ) {
              this.errors.email = true
              this.message.email = 'Email sudah terdaftar.'
            } else if (
              data.data?.regsData.errors[0].message ===
              'Nomor Whatsapp telah digunakan'
            ) {
              this.errors.whatsapp = true
              this.message.whatsapp = 'Nomor Whatsapp sudah digunakan.'
            } else {
              this.errors.general = true
              this.message.general = 'Terjadi kesalahan, silahkan coba kembali.'
            }
            this.registerSubmitProcess = false
          } else {
            if (this.redirect) {
              window.location.assign(this.redirect)
            } else {
              await Auth.checkUser(Cookies.getJSON('auth'), false, false)
            }
            this.registerSubmitProcess = false
          }
        }
      } else {
        if (!this.registerForm.agreement) {
          this.errors.agreement = true
        }
        return
      }
    },

    async loginSubmit() {
      this.emailFieldStatus = false
      this.passwordFieldStatus = false

      if (!this.loginForm.email || !this.loginForm.password) {
        if (!this.loginForm.email) {
          this.emailFieldStatus = true
        }
        if (!this.loginForm.password) {
          this.passwordFieldStatus = true
        }
        return
      }

      this.loginSubmitProcess = true
      try {
        const data: any = await Auth.Login(
          this.loginForm.email,
          this.loginForm.password
        )
        if (data?.data == 'reset-password') {
          this.passwordFieldStatus = true
          this.toast.error(ResetPassword)
        } else if (data?.data == 'verify-email') {
          this.$router.push('/verify-email')
        } else {
          if (data?.data === false) {
            this.emailFieldStatus = true
            this.passwordFieldStatus = true
          } else {
            if (data?.data?.regsData?.success == false) {
              if (
                data?.data?.regsData?.errors[0]?.message ==
                'Email sudah digunakan'
              ) {
                this.passwordFieldStatus = true
              } else {
                this.emailFieldStatus = true
                this.passwordFieldStatus = true
              }
            } else {
              if (this.redirect) {
                window.location.assign(this.redirect)
              } else {
                await Auth.checkUser(Cookies.getJSON('auth'), false, false)
              }
            }
          }
        }
        this.loginSubmitProcess = false
      } catch (error) {
        this.emailFieldStatus = true
        this.passwordFieldStatus = true
        this.loginSubmitProcess = false
      }
    },
    popup() {
      const route = process.env.VUE_APP_IDP_FORGOT_PASSWORD
      window.open(route, '_blank', 'width=900, height=600')
    },
  },
})
